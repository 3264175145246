<template>
  <Wrapper>
    <header>{{ $tc('confirm.edit.subsidiary') }}</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="'Subsidiary'" />
    </InputStyled>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <ButtonsGroup>
      <ButtonStyled :disabled="isApplyButtonDisabled" @click="editSubsidiaryName">
        {{ $tc('actions.apply') }}
      </ButtonStyled>
      <ButtonStyled :disabled="isLoading" color="grey" @click="$root.$emit('closeOverlay')">
        {{ $tc('actions.cancel') }}
      </ButtonStyled>
    </ButtonsGroup>
  </Wrapper>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'
import UPDATE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/update.gql'

import { Wrapper, InputStyled, ButtonsGroup, ButtonStyled } from './styles'
import { FlashMessages } from '@common/singletons'

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    Wrapper,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  data() {
    return {
      name: '',
      initialName: '',
      isLoading: false,
    }
  },
  computed: {
    organizationID() {
      return this.args.organizationID
    },
    subsidiary() {
      return this.args.subsidiary
    },
    isApplyButtonDisabled() {
      return !this.$v.name.required || !this.$v.name.minLength || this.isLoading || this.name === this.initialName
    },
  },
  watch: {
    subsidiary: {
      handler(subsidiary) {
        this.name = subsidiary.name
        this.initialName = subsidiary.name
      },
      immediate: true,
    },
  },
  methods: {
    async editSubsidiaryName() {
      await this.$apollo.mutate({
        mutation: UPDATE_SUBSIDIARY_MUTATION,
        variables: {
          where: {
            id: this.subsidiary.id,
          },
          data: {
            name: this.name,
          },
        },
        update: store => {
          const { organization } = store.readQuery({
            query: ORGANIZATION_QUERY,
            variables: {
              where: {
                id: this.organizationID,
              },
            },
          })

          organization.subsidiaries = organization.subsidiaries.map(item =>
            item.id === this.subsidiary.id ? { ...item, name: this.name } : item,
          )

          store.writeQuery({
            query: ORGANIZATION_QUERY,
            variables: {
              where: {
                id: this.organizationID,
              },
            },
            data: {
              organization,
            },
          })
        },
      })

      FlashMessages.$emit('success', this.$t('messages.entityUpdatedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
        timeout: 3000,
      })

      this.$root.$emit('closeOverlay')
    },
  },
}
</script>
